<template>
    <section class="orderconfirmation">
        <h3 class="h3 border-divider mb-4">
            {{ t('checkout_OrderDetails') }}
        </h3>

        <div v-if="bulkOrder">
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('ordernumber') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ getOrderNumberDisplay }}</span>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span class="capitalize">{{ t('checkout_OrderDate') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ get(bulkOrder, 'createdDate') }}</span>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('checkout_Customer') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ get(bulkOrder, 'customerName') }}</span>
                    <span v-if="get(bulkOrder, 'accountNumber')" class="text-muted">
                        ({{ get(bulkOrder, 'accountNumber') }})</span
                    >
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('statusNormalCase') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ get(bulkOrder, 'orderStatus') }}</span>
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col sm="6" md="4" xl="3">
                    <span class="capitalize">{{ t('checkout_PurchaseNumber') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{
                        get(bulkOrder, 'purchaseOrderNumber')
                    }}</span>
                </b-col>
            </b-row>

            <div v-if="this.hasBulkOrderItems" class="border-divider pb-5">
                <h4 class="h4 mb-2 border-divider font-weight-bold">
                    {{ t('checkout_ItemInOrder') }}
                </h4>

                <div>
                    <b-table
                        :items="bulkOrder.items"
                        :fields="tableFields"
                        striped
                        hover
                        responsive
                        small
                        sticky-header
                        class="table-list"
                        @row-clicked="openLensDetailsModal"
                    >
                        <template #cell(serialNumber)="data">
                            {{ getFirstThreeSerialNumbers(data.item.reservations) }}
                        </template>
                        <!-- <template #cell(expirationDate)="data">
                            {{ getFirstThreeExpirationDates(data.item.reservations) }}
                        </template> -->
                        <template #cell(description)="data">
                            {{ getDescriptionFormatted(data.item.description) }}
                        </template>
                        <template #cell(salesCategory)="data">
                            {{ data.item.salesCategory }}
                        </template>
                        <template #cell(sphere)="data">
                            {{ getDiopterFormatted(data.item.sphere) }}
                        </template>
                        <template #cell(cylinder)="data">
                            {{ getDiopterFormatted(data.item.cylinder) }}
                        </template>
                        <template #cell(axis)="data">
                            {{ data.item.axis }}
                        </template>
                        <template #cell(quantity)="data">
                            {{ getQuantityFormatted(data.item) }}
                        </template>
                    </b-table>
                </div>

                <b-pagination
                    v-model="currentPage"
                    :total-rows="bulkOrder.totalRecords"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                ></b-pagination>
            </div>
        </div>

        <!-- Lens Details Modal -->
        <b-modal
            v-model="lensDetailsModalShow"
            size="lg"
            :title="t('lensDetails')"
            :body-class="'lens-details-modal-body'"
            :header-bg-variant="'light'"
            :header-text-variant="'dark'"
            :footer-bg-variant="'light'"
            :footer-text-variant="'dark'"
            hide-footer
            centered
        >
            <div v-if="isLoading">
                <b-spinner label="Loading..." small></b-spinner> {{ t('_loading') }}
            </div>
            <div v-else class="scrollable-modal-content">
                <b-table
                    :items="selectedLensDetails"
                    :fields="lensDetailsFields"
                    striped
                    hover
                    responsive
                    small
                >
                    <template #cell(serialNumber)="data">
                        {{ data.item.serialNumber }}
                    </template>
                    <!-- <template #cell(expirationDate)="data">
                        {{ data.item.expirationDate }}
                    </template> -->
                    <template #cell(description)="data">
                        {{ data.item.description }}
                    </template>
                    <template #cell(axis)="data">
                        {{ data.item.axis }}
                    </template>
                </b-table>
            </div>
        </b-modal>
    </section>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import get from 'lodash/get';
import date from '@/filters/date';

export default {
    props: {
        bulkOrderId: {
            type: String,
            default: () => null,
        },
    },
    data() {
        return {
            lensDetailsModalShow: false,
            bulkOrder: null,
            selectedLensDetails: [],
            isLoading: false,
            currentPage: 1,
            perPage: 300,
            tableFields: [
                {key: 'serialNumber', label: this.t('serialNum')},
                // {key: 'expirationDate', label: this.t('expirationDate')},
                {key: 'description', label: this.t('description_label')},
                {key: 'salesCategory', label: this.t('salesCategory')},
                {key: 'sphere', label: this.t('sphere_label')},
                {key: 'cylinder', label: this.t('cylinder_label')},
                {key: 'axis', label: this.t('axis_label')},
                {key: 'quantity', label: this.t('quantity_label')},
            ],
            lensDetailsFields: [
                {key: 'serialNumber', label: this.t('serialNum')},
                // {
                //     key: 'expirationDate',
                //     label: this.t('expirationDate'),
                //     formatter: (value) => date(value),
                // },
                {key: 'description', label: this.t('description_label')},
                {key: 'axis', label: this.t('axis_label')},
            ],
        };
    },
    computed: {
        ...mapGetters('orders', ['getSelectedTotal']),
        ...mapGetters('user', ['currentUser']),
        getOrderNumberDisplay() {
            const bulkOrderNumbers = get(this.bulkOrder, 'oracleOrderNumbers', []);
            if (bulkOrderNumbers.length > 0) {
                return bulkOrderNumbers.join(', ');
            }
            return this.t('pending');
        },
        hasBulkOrderItems() {
            return !!this.bulkOrder.items?.length;
        },
    },
    methods: {
        ...mapMutations('cart', ['setSummary']),
        get,

        getDiopterFormatted(diopter) {
            let diopterValue =
                diopter != null
                    ? diopter
                          .toFixed(2)
                          .toString()
                          .replace(/^\d/, (match) => (diopter > 0 ? '+' : '') + match)
                    : '';

            if (this.currentUser.decimalSeparator === ',') {
                diopterValue = diopterValue.replace('.', ',');
            }
            return diopterValue;
        },

        getDescriptionFormatted(description) {
            return description
                .replace(/\s*\(.*?\)\s*/g, '')
                .replace(/^(\S+\s\S+).*/, '$1')
                .trim();
        },

        getFirstThreeSerialNumbers(reservations) {
            reservations = reservations?.filter((v) => v.serialNumber != null);
            return reservations == null || reservations?.length == 0
                ? ''
                : reservations
                      ?.map((r) => r.serialNumber)
                      .slice(0, 3)
                      .join(', ') + (reservations.length > 3 ? '...' : '');
        },
        getFirstThreeExpirationDates(reservations) {
            reservations = reservations?.filter((v) => v.expirationDate != null);
            return reservations == null || reservations?.length == 0
                ? ''
                : reservations
                      ?.map((r) => date(r.expirationDate))
                      .slice(0, 3)
                      .join(', ') + (reservations.length > 3 ? '...' : '');
        },
        getQuantityFormatted(item) {
            return (item.reservations?.length ?? 0) + '/' + item.quantity;
        },
        async fetchBulkOrder(page = 1, perPage = 300) {
            try {
                const {success, results} = await this.blockingRequest('orders/getBulkOrder', {
                    bulkOrderId: this.bulkOrderId,
                    page: page,
                    per_page: perPage,
                });
                if (success) {
                    this.bulkOrder = results;
                    this.$store.dispatch('orders/updateTotalRecords', results.totalRecords);
                }
            } catch (error) {
                this.$router.push({
                    name: 'NotFound',
                });
            }
        },
        async openLensDetailsModal(item) {
            if (item.reservations?.length > 3) {
                this.selectedLensDetails = item.reservations.map((r) => {
                    return {...r, description: item.description};
                });
                this.lensDetailsModalShow = true;
            }
        },
    },
    watch: {
        currentPage(newPage) {
            this.fetchBulkOrder(newPage, this.perPage);
        },
        perPage(newPerPage) {
            this.fetchBulkOrder(this.currentPage, newPerPage);
        },
    },
    async created() {
        if (isNaN(this.bulkOrderId)) {
            this.$router.push({name: 'NotFound'});
            return;
        }
        await this.fetchBulkOrder(this.currentPage, this.perPage);
        this.setSummary({
            isCartSummary: false,
            orderTotal: this.getSelectedTotal,
            fixedFreightCharge: 0,
        });
    },
    beforeMount() {
        this.$emit('update:page-title', this.t('checkout_BulkOrderDetails'));
    },
    destroyed() {
        this.setSummary(null);
        this.$emit('update:page-title', this.t('checkout_ShoppingCart'));
    },
};
</script>
<style lang="scss" scoped>
.capitalize {
    text-transform: capitalize;
}
.border-divider {
    border-bottom: 3px solid #91979d;
    padding-bottom: 30px;
}

::v-deep .table-list {
    tr {
        cursor: pointer;
    }
}

.lens-details-modal-body {
    max-height: 75vh; /* 3/4 of the viewport height */
    overflow-y: auto;
    padding-right: 15px;
}

.scrollable-modal-content {
    max-height: 60vh;
    overflow-y: auto;
}
</style>
